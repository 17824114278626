<script>
import InputField from '@/components/general/InputField.vue'
import ExternalItemLink from '@/components/general/ExternalItemLink'

export default {
  name: 'ModalLinkedPositionsDraft',
  components: { InputField, ExternalItemLink },
  data () {
    return {
      search: '',
      isReady: false,
      positions: []
    }
  },
  created () {
    this.getDepartmentDetails()
  },
  methods: {
    getDepartmentDetails () {
      this.$store.dispatch('attemptGetDepartmentDetails', this.$route.params.id)
        .then(({ data }) => {
          this.isReady = true
          this.positions = data.positions.filter(p => p.status === 'draft').map((position) => (
            {
              id: position._id,
              code: position.code,
              name: position.title,
              location: position.locationName,
              createdAt: this.formatDate(position.positionCreatedAt)
            }
          ))
          this.data = this.positions
        })
    }
  },
  watch: {
    search: function (value) {
      this.positions = this.data.filter(position => {
        return position.name.toLowerCase().indexOf(value.toLowerCase()) > -1
      })
    }
  }
}
</script>
<template>
  <div class="modal-linked-positions-draft--container" v-if="isReady">
    <div class="modal-linked-positions-draft--actions">
      <input-field outlined dense :label="$t('global:search.alt')" v-model="search"
        append-icon="mdi-magnify"></input-field>
    </div>
    <div class="modal-linked-positions-draft--body">
      <external-item-link v-for="position in positions" :key="position.id"
        :item="{ name: position.name, city: position.location, id: position.code, date: position.createdAt, click: { id: position.id } }">
      </external-item-link>
      <v-card class="content-empty-list--container max-auto" flat v-if="positions.length == 0">
        <div class="content-empty-list--header">
          <h5 class="content-empty-list--title">{{ $t('empty.state.title:department.linked.draft.title') }}</h5>
        </div>
      </v-card>
    </div>
</div>
</template>
<style lang="scss">
.modal-linked-positions-draft--container {
  .modal-linked-positions-draft--actions {
    max-width: 200px;
    margin-left: auto;
  }

  .content-empty-list--container {
    margin-bottom: 60px;
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .content-empty-list--header {
      text-align: center;

      .content-empty-list--title {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        color: #212121;
        margin-top: 24px;
      }
    }
  }
}

@media only screen and (max-width: 640px) {
  .modal-linked-positions-draft--container {
    .modal-linked-positions-draft--actions {
      max-width: 100%;
    }
  }
}
</style>
